<template>
  <div class="form-group">

    <label v-if="label" class="form-label">{{label}}</label>

    <div class="range-wrap"
         @click="onClick">
      <div class="form-range"
           :class="style"
           ref="range">
        <span class="range-road">
          <span class="range-fill" :style="{width: position+'%'}"></span>
        </span>
        <span class="range-handle"
              :style="{left: position+'%'}"
              @touchstart="grabStart"
              @mousedown="grabStart"></span>
      </div>
    </div>

    <span v-if="help !== undefined" class="form-help">{{help}}</span>
  </div>
</template>

<script>
  export default {
    props: {
      placeholder: [String, Number],
      label: [String, Number],
      help: {
        type: [String, Number],
        default: undefined
      },
      value: {
        type: Number,
        default: 0
      },
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 100
      },
      step: {
        type: Number,
        default: 1
      },
      valid: {
        type: Boolean,
        default: true
      },
      warning: {
        type: Boolean,
        default: false
      },
      success: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      _value: {
        get () { return this.value },
        set (value) {
          value = Math.floor(value / this.step) * this.step;

          if(value < this.min)
            value = this.min;

          if(value > this.max)
            value = this.max;

          this.$emit('input', value);
        }
      },
      position() {
        return Math.floor((this._value - this.min) / (this.max - this.min) * 100);
      },
      style() {
        return {
          'active': this.mouseIsDown,
          'range-success': this.success,
          'range-warning': this.warning,
          'range-danger': !this.valid,
          'range-primary': this.valid
        }
      }
    },
    data() {
      return {
        mouseIsDown: false
      }
    },
    mounted() {
      document.addEventListener('touchend', () => { this.grabEnd() });
      document.addEventListener('mouseup', () => { this.grabEnd() });
      document.addEventListener('mousemove', (e) => {
        if(this.mouseIsDown)
          this.setPos(e);
      });
    },
    methods: {
      grabStart(e) {
        e.preventDefault();
        this.mouseIsDown = true;
        this.$emit('dragstart', this._value);
      },
      setPos(e) {
        let rect = this.$refs['range'].getBoundingClientRect();
        let x = e.clientX - rect.left;

        this._value = this.min + (x / rect.width * (this.max - this.min));

        this.$emit('drag', this._value);
      },
      onClick(e) {
        let rect = this.$refs['range'].getBoundingClientRect();
        let x = e.clientX - rect.left;

        this._value = this.min + (x / rect.width * (this.max - this.min));

        this.$emit('click', this._value);
      },
      grabEnd() {
        this.mouseIsDown = false;
        this.$emit('dragend', this._value);
      }
    }
  }
</script>
